@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,700&display=swap');

/* Variables */
:root{
  --PrimaryFont : 'Fira Code';
  --SecondaryFont : 'Lato';
  --PrimaryColor:#F2AF29;
  --PrimaryColor-op:rgba(242, 175, 41, 0.77);
  --SecondaryColor:#FDFFFC;
  --Color-3:#020100;
  --Color-3-10per:rgba(2, 1, 0, 0.1);
  --Color-4:#7A9E9F;
  --Color-5:#235789;
  --Color-5-op:rgba(35, 88, 137, 0.8);
}
*,
*::after,
*::before {
  padding:0;
  margin:0;
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 50%; /*8px/16px = 50% --> 1rem = 8px */
  scroll-behavior: smooth;
  
}
body {
  font-family: var(--PrimaryFont) ;
  line-height:1.6182;
  overflow-x:hidden ;
}
a {text-decoration:none ;color:inherit;}

::selection{
  background-color: var(--PrimaryColor);
  color:var(--SecondaryColor);
}

@media only screen and (max-width:81.25em) { /* break point 1300px*/
  html {
    font-size:43.75%; /* 7px /16px --> 1rem = 7px*/
  }
}

@media only screen and (max-width:67.5em) { /* break point 1080px*/
  html {
    font-size:37.5%; /* 6px /16px --> 1rem = 6px*/
  }
}
@media only screen and (max-width:56.25em) { /* break point 900px*/
  html {
    font-size:31.25%; /* 5px /16px --> 1rem = 5px*/
  }
}
@media only screen and (max-width:46.875em) { /* break point 750px*/
  html {
    font-size:28.125%; /* 4.5px /16px --> 1rem = 4.5px*/
  }
}
@media only screen and (max-width:37.5em) { /* break point 600px*/
  html {
    font-size:25%; /* 5px /16px --> 1rem = 5px*/
  }
}



